import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import ReactCompareImage from 'react-compare-image';
import   { useState, useRef } from "react";
import ValueForm from './valueForm';

const Values = () => {
 
    return(
        <div>
            <Container className="py-5 text-center  ">
                 <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Our</span>
            <span className="blueBor"> Values</span>
          </h1>

          <Row>
           

            <Col sm="12" className="compare" >
            <div className='compareDiv' style={{ maxWidth: "650px", margin: "auto" }}>

            <ReactCompareImage leftImage="assets/images/innovera/3dicons/values/v1.jpeg" rightImage="assets/images/innovera/3dicons/values/v2.png" />

            <br></br>


                </div>
            </Col>
            <Col sm="12" className='mb-5'>
            <ValueForm/>

            
           
             </Col>
          </Row>
                 

                <Row>
                    <Col lg={{span:6, offset:3}} className='text-center'>

                 <a href="./assets/images/innovera/SLIDE-9-The-Culture-Book.pdf" download>   <div class="BranchBox3  text-center">DOWNLOAD OUR CULTURE BOOK </div></a>
                    
                    </Col>
                </Row>
            </Container>
        </div>
    );
 
    }
export default Values;