import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';
import ContactMainForm from './contact_main_form';
import Sidebar from '../layout/sidebar';

// --------------------------------------------

export const schoolImg = [
  //   {
  //     imgUrl: 'assets/images/innovera/school/delivery_in.jpeg',
  //     imgCaption:'Delivering Inspiration',
  //     url:'deliver-inspiration'

  // },

  {
    imgUrl: 'assets/images/innovera/2024/Innovera_Constellation_24_25/11.jpg',
    imgCaption:'Constellation 24 25',
    url:'constellation-24-25'

},
{
  imgUrl: 'assets/images/innovera/2024/Innovera_expo_24_24/1.jpg',
  imgCaption:'Expo 2024-25',
  url:'expo-2024'

},


{
  imgUrl: 'assets/images/innovera/2024/Innovera_Independence_Day/1.jpg',
  imgCaption:'Independence Day 2024',
  url:'independence-day-2024'

},
{
imgUrl: 'assets/images/innovera/2024/Innovera_Mumbai_Trip/9.jpg',
imgCaption:'Mumbai Trip 2024',
url:'mumbai-trip-2024'

},


{
  imgUrl: 'assets/images/innovera/2024/Grand_Parents_Day/Grandparents-day.jpg',
  imgCaption:'Grand Parents Day',
  url:'grand-parents-day'

},
{
  imgUrl: 'assets/images/innovera/2024/Innovera_School_Sports_Day_Pre_school/3.jpg',
  imgCaption:'Sports Day Pre School',
  url:'sports-day-pre-school'

},
{
  imgUrl: 'assets/images/innovera/2024/Jr_Kg_Family_Day/3.jpg',
  imgCaption:'Jr Kg Family Day',
  url:'jr-kg-family-day'

},
{
        imgUrl: 'assets/images/innovera/school/Sports-Day.jpg',
        imgCaption:'Sports day ',
        url:'annual-sport'

  },
    {
      imgUrl: 'assets/images/innovera/expo_preparation/1.jpg',
      imgCaption:'Expo Preparation',
      url:'expo-preparation'

  },
  {
    imgUrl: 'assets/images/innovera/expo23/5.jpeg',
    imgCaption:'Expo 2023-24',
    url:'expo-2023'
  
  },
//   {
//     imgUrl: 'assets/images/innovera/school/expo1.jpeg',
//     imgCaption:'Annual Expo',
//     url:'expo-2021'


// },
{
    imgUrl: 'assets/images/innovera/garba-night/7.jpg',
    imgCaption:'Garba night',
    url:'garba-night'

},
{
    imgUrl: 'assets/images/innovera/rakhi-making/9.jpeg',
    imgCaption:'Rakhi Making',
    url:'rakhi-making'


},
{
    imgUrl: 'assets/images/innovera/school/bhondla.jpg',
    imgCaption:'Sarsvati Poojan-Bhondla celebration',
    url:'sarsvati-poojan-bhondla'


},
{
    imgUrl: 'assets/images/innovera/puberty/1.jpeg',
    imgCaption: 'Puberty and Adolescence Session',
    url:'puberty-adolescence'
},
// {
//     imgUrl: 'assets/images/innovera/school/night_camp.jpeg',
//     imgCaption: 'Night camp',
//     url:'night-camp'

// },

//   {
//     imgUrl: 'assets/images/innovera/field-trip/1.jpeg',
//     imgCaption:'Field trip',
//     url:'field-trip'

// },

  {
    imgUrl: 'assets/images/innovera/foster/1.jpeg',
    imgCaption:'Foster talk & Parents as Educator',
    url:'foster'

},

{
  imgUrl: 'assets/images/innovera/library/1.jpeg',
  imgCaption:'Library',
  url:'library'

},
{
  imgUrl: 'assets/images/innovera/parent_get_together/10.jpg',
  imgCaption:'Parents get together',
  url:'parents-get-together'

},
{
  imgUrl: 'assets/images/innovera/Constellation/Constellation-5.jpg',
  imgCaption:'Constellation',
  url:'constellation'

},
{
  imgUrl: 'assets/images/innovera/Friendship/Friendship-1.jpg',
  imgCaption:'Friendship day',
  url:'friendship-day'

},
  
{
  imgUrl: 'assets/images/innovera/children_day/4.jpg',
  imgCaption:'Childrens day',
  url:'childrens-day'

},
// {
//   imgUrl: 'assets/images/innovera/sst-labs/4.jpeg',
//   imgCaption:'Resource Room',
//   url:'resource-room'

// },


{
  imgUrl: 'assets/images/innovera/mumbai_picnic/image-10.jpg',
  imgCaption:'Unusual Mumbai Trip - Class 10',
  url:'mumbai-picnic'

},
//     {
//       imgUrl: 'assets/images/innovera/school/smartclass.jpg',
//   },
// {
//         imgUrl: 'assets/images/innovera/school/smartclass2.jpeg',
//   },
//   {
//     imgUrl: 'assets/images/innovera/school/smartclass3.jpeg',
// },
  

//     {
//       imgUrl: 'assets/images/innovera/school/class1.jpeg',
//   },
// {
//         imgUrl: 'assets/images/innovera/school/class2.jpeg',
//   },
//   {
//     imgUrl: 'assets/images/innovera/school/class3.jpeg',
// },
  
]
// --------------------------------------------


class ImpressionsPage extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Gallery' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="   growSection  impressions">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5  "  rounded>Gallery </h2>
                  </Col>
  
                  </Row>
                <Row>        
                {schoolImg.map( (val , i) => (  

              <Col lg='6'  key={i}>
              <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
              <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
                <span class="plus"> <NavLink to={`/${ val.url} `} > <i class="fa fa-arrow-circle-o-right"></i> </NavLink></span>
  					     </div>
				         </div>
               </Col>                         )  )}


                         </Row>
      
</Container>

         </Col>
         <Col lg="3">
         <Sidebar/>

         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default ImpressionsPage;