import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';

class Branches extends Component {
        render() {

    return(
        <div >
            <Container className="py-5 text-center">
             <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Our</span>
            <span className="greenBor"> Branches</span>
          </h1>                    
                <Row className='pb-4'>
                <div className="col-sm-4 offset-sm-4 ">
        
        <div className="BranchBox">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Innovera School 
         </h4>
         <p class="firstp">Near Riverview City,<br></br> Kadam Wak Wasti, Pune – Solapur, Pune, Maharashtra  </p>
         <p><i className="fa fa-phone"></i> <a href="tel: +919168290611 "> 91682 90611 </a> |  <a href="tel: +917387332255"> 73873 32255</a></p>

     </div>
     </div>
                </Row>

                <Row className='pb-4'>

                <div className="col-sm-4  ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Kawadipath - Pune
         </h4>
         <p  class="firstp">Lane no 2, Ganaraj Park, Kalbhor Nagar, Kawadipath, Haveli, Pune.</p>
         <p><i className="fa fa-phone"></i> <a href="tel: +918055405146 "> 80554 05146 </a>  </p>

     </div>
     </div>

     <div className="col-sm-4  ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Takalenagar - Pune 
         </h4>
         <p  class="firstp"> Krutarth Building,Nandini Takale Nagar,Opposite To Shevalwadi Naka,Manjri Bk,Hadapsar,Pune</p>
         <p><i className="fa fa-phone"></i> <a href="tel: +919960159400 "> 99601 59400 </a>  </p>

     </div>
     </div>
     
               



     <div className="col-sm-4  ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Manjri Greens - Pune
         </h4>
         <p  class="firstp">1A Manjri Greens, Phase 1, Pune Solapur Road, Near You Hospital, Pune.</p>
         <p><i className="fa fa-phone"></i> <a href="tel: +91 9422512705 ">  94225 12705 </a>  </p>

     </div>
     </div>

 
     <div className="col-sm-4  ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Gondhalenagar - Pune
         </h4>
         <p  class="firstp">9. Sr no 18/1d, House No 628, Gondhalenagar, Rakhumai Sankul Hadapsar, Pune.</p>
         <p><i className="fa fa-phone"></i> <a href="tel: +917798204875 "> 77982 04875 </a> </p>

     </div>
     </div>


     <div className="col-sm-4   ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Phursungi - Pune
         </h4>
         <p  class="firstp">Sai Suman Building, Behind Maharashtra Bakery, Near Deshmukh Petrol Pump, Bhekrai Nagar, Phursungi Pune.</p>
         <p><i className="fa fa-phone"></i> <a href="tel: +919049303001"> 90493 03001 </a>  </p>

     </div>
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Sasanenagar - Pune
         </h4>
         <p  class="firstp">C Wing ,203/204 ,Gandharva Geet , Sasane nagar, Hadapsar, Pune.  </p>
         <p><i className="fa fa-phone"></i>   <a href="tel: +918408010794"> 84080 10794</a></p>

     </div>
     </div>
            
     </Row>
 
 
     <Row className='pb-4'>
        
               

     <div className="col-sm-4   offset-sm-2">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Lohegaon - Pune   
         </h4>
         <p  class="firstp">Vrindavan Apartment, Sr.no - 58/3/1 (Opp.Mauli Khandve Gotha) Pathare Vasti, Lohegaon, Pune  </p>
         <p><i className="fa fa-phone"></i> <a href="tel: +919359567057 "> 93595 67057 </a> </p>

     </div>
     </div>

     <div className="col-sm-4  ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Dandia Bazar - Vadodara
         </h4>
         <p  class="firstp"> Shankar Tekri, Opp Kala Bhavan College, Near Indu Class Dandia Bazar, Vadodara.  </p>
         <p><i className="fa fa-phone"></i> <a href="tel: +919909918643 "> 99099 18643 </a> |  <a href="tel: +917387332255"> 73873 32255</a></p>

     </div>
     </div>

    
                </Row>


                
              

                 
                <Row>

                <div className="col-sm-4 offset-sm-4 ">
      <a href='https://mi-school.org/' target='_blank'>  
         <img src="assets/images/innovera/impact/MI-logo.jpg" style={{ maxWidth: "40%" }}
 className='img-fluid'></img>
         <h4 className='text-dark'>
         An Innovera Preschool Initiative

         </h4>
         
      </a>
     </div>
                </Row>
                <div className="col-sm-4 offset-sm-4 ">
                 

                
      <NavLink to="/grow-with-us"   activeclassname="active">  
      <button type="button" class="btn btn-success">    Grow With Us</button>

   </NavLink>
     </div>
                <Row>
                </Row>

                {/* <Row>
                <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
 
         </h4>
         <p>Intisar Palace, Plot No.32, Near Sai Hospital, Bhosale village society, opp MSEB power house, Phursungi, Pune Maharashtra 412308
</p>
<p><i class="fa fa-phone"></i> <a href='tel:7218967709'>72189 67709</a> | <a href='tel:9860832577'>98608 32577</a> </p>

     </div>
     </div>

     <div className="col-sm-4">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids  
         </h4>
         <p>Flat No.1202, B-3, Grandbay Society, Solapur Highway, Manjri, Pune, Maharashtra 412307
</p>
<p><i class="fa fa-phone"></i> <a href='tel:9960159400'>99601 59400</a> </p>

     </div>
     </div>


     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids

         </h4>
         <p> Lane no 2, Ganaraj Park, Kalbhor Nagar, Kawadipath, Haveli, 
Pune, Maharashtra  
</p>
<p><i class="fa fa-phone"></i> <a href='tel:8055405146'>80554 05146</a> </p>
     </div> 
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
  
         </h4>
         <p>Sr no 18/1d, House No 628, Gondhalenagar, Rakhumai Sankul, Hadapsar, Pune, Maharashtra 411028
</p>
<p><i class="fa fa-phone"></i> <a href='tel:7798204875'>77982 04875</a> </p>

     </div>
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
  
         </h4>
         <p>Prime Point Rd, Nandini Takle Nagar, Manjri Bk, Pune, Maharashtra, 412307 </p>
         <p><i class="fa fa-phone"></i> <a href='tel:9960159400'>99601 59400</a> </p>

     </div>
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
  
         </h4>
         <p>Opposite Manjri Stud Farm, ( NH 9 ) Solapur Road, Manjri Greens Society, Manjri Bk, Pune, Maharashtra, 412307 </p>

         <p><i class="fa fa-phone"></i> <a href='tel:9422512705'>94225 12705</a> </p>

     </div>
     </div>
                </Row> */}


            
            </Container>
        </div>
    );
}
    }
export default Branches;