import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Sidebar from '../layout/sidebar';

class Beyond extends Component {
        render() {

    return(
        <div>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Beyond' subtitle='Academics'/>
      <Container  >
      <Row>
      <Col lg="9">

            <Container className="py-5 text-center beyondIcons">
            <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Beyond</span>
            <span className="blueBor"> Academics</span>
          </h1>            
          <p>
          Exposure to different environment stimuli can nurture young and impressionable minds. To know any child’s strength, we focus on the subjects apart from the academics, to ensure overall development.
          </p>
              <Row className='py-4' >
                <Col sm="6" lg="3">
                <Image src='assets/images/innovera/3dicons/beyond/yoga.png' alt="" fluid />
                <h4>Yoga</h4>
                 </Col>
                <Col  sm="6" lg="3">   
                <Image src='assets/images/innovera/3dicons/beyond/music-and-dance.png'  alt="" fluid /> 
                <h4>Music and Dance</h4>
                  </Col>
                <Col  sm="6" lg="3"> 
                 <Image src='assets/images/innovera/3dicons/beyond/Art-and-craft.png' alt="" fluid /> 
                 <h4>Art and Craft</h4>
                </Col>
                <Col  sm="6" lg="3"> 
                 <Image src='assets/images/innovera/3dicons/beyond/various-Sports.png' alt="" fluid />
                 <h4>Various Sports</h4>
                 </Col>
            </Row>

                <Row className='pb-4'>
                <Col sm="6" lg="3">  <Image src='assets/images/innovera/3dicons/beyond/Martial-art.png' alt="" fluid /> 
                <h4>Martial Art</h4>
                </Col>
                <Col  sm="6" lg="3">   <Image src='assets/images/innovera/3dicons/beyond/Rotary-interact-club.png'  alt="" fluid /> 
                <h4>Rotary Interact Club</h4>

                  </Col>
                <Col  sm="6" lg="3">  <Image src='assets/images/innovera/3dicons/beyond/Toastmasters-club.png' alt="" fluid />
                <h4>Toastmasters Club</h4>
 </Col>
                <Col  sm="6" lg="3">  <Image src='assets/images/innovera/3dicons/beyond/Foasters-talk.png' alt="" fluid />
                
                <h4>Foster Talks</h4> </Col>
            </Row>
            </Container>

        <div className='grayBg'>
            <Container className='py-5'>
            <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">We prepare</span>
            <span className="greenBor"> our students</span>
          </h1> 
                <Row>
                    <Col lg="6">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/impact/Tomarow leader.jpg" />
                    <Card.Body>
                        <Card.Title> TOMORROW'S LEADERS </Card.Title>
                        <Card.Text>
                        Leaders are made. We nurture children through academics, athletics, and values.

                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg="6">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/impact/Strong-Bond-1.jpg" />
                    <Card.Body>
                        <Card.Title> Strong bond </Card.Title>
                        <Card.Text>
                        At Innovera, we build lasting connections between students, parents, and our team that endure forever.

                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg={{ span:6, offset: 3 }}  >
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/beyonds_academics/ctitc.jpg" />
                    <Card.Body>
                        <Card.Title> Critical thinkers</Card.Title>
                        <Card.Text>
                        We empower our students to think critically and act decisively, preparing them for the real world.

                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                   
                </Row>
            </Container>
            </div>

            </Col>
                <Col lg="3">
         <div className=" py-5 services-details-area">

<Sidebar/>
</div>

         </Col>

         </Row>
         
         </Container>

         </div>

     );
}
    }
export default Beyond;