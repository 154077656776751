import React, { Component , useEffect }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import ContactMainForm from '../section/contact_main_form';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

     const Footer = () => {
   
  const [isActive, setIsActive] = useState();

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
  };



  const [displayPopUp, setDisplayPopUp] = useState(true);

  // when pop-up is closed this function triggers
  const closePopUp = () => {
    // setting key "seenPopUp" with value true into localStorage
    localStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setDisplayPopUp(false);
  };

  // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
  useEffect(() => {
    // getting value of "seenPopUp" key from localStorage
    let returningUser = localStorage.getItem("seenPopUp");
    // if it's not there, for a new user, it will be null
    // if it's there it will be boolean true
    // setting the opposite to state, false for returning user, true for a new user
    setDisplayPopUp(!returningUser);
  }, []);


     return(
        <div className='footerSection newFooter'>
          {/* row-cols-4  */}
     <Container fluid  > 

      <div className='row py-4 partners text-center newFooter'>
        <div className='col-sm-12'>
        <h4> Affiliated To</h4>

        {[...Array(5)].map((e, i) => {
                                  
                                  return  <span lg='1'   key={i + 1} >
                  
                 <Image src= {(`assets/images/innovera/partners/Logo-${i+1 }.jpg`)} fluid/>
             
 
              </span>                        })}
        </div>
      </div>
     <footer className="row  py-4  text-center ">
    <Col lg="3">
      <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
      
      <p className="text-white"><Image src="assets/images/innovera/new_design/logo.png"  fluid/></p>
      </a>
    </Col>

    

    <Col lg="6" className='text-center'>      
      <ul className="nav mx-auto justify-content-center">
        <li className="nav-item mb-2"> <NavLink to="/" >Home | </NavLink>
</li>
        
        <li className="nav-item mb-2">  <NavLink to="/careers" > &nbsp;Join the Family | </NavLink>
</li>
<li className="nav-item mb-2">  <NavLink to="/contact" > &nbsp;Contact | </NavLink>
</li>
<li className="nav-item mb-2">  <NavLink to="/our-branches" > &nbsp;Our Branches |</NavLink>
</li>
<li className="nav-item mb-2">  <NavLink to="/privacy-policy" > &nbsp;Privacy Policy |</NavLink>
</li>
<li className="nav-item mb-2">  <NavLink to="/terms_and_conditions" > &nbsp;Terms & Conditions</NavLink>
</li>

       </ul>



       <p className='text-center text-dark'>All Rights Reserved @ Shiksha Education Society
</p>
    </Col>

    <Col lg="3">
    <ul className="nav">

        <li> <a href="https://www.facebook.com/InnoveraSchool/ " target="_blank"><i className="fa fa-facebook"></i></a> </li>
        <li> <a href="https://www.instagram.com/innovera_school/" target="_blank"><i className="fa fa-instagram"></i></a></li>
        <li> <a href="https://www.youtube.com/@innoveraschool1704" target="_blank"><i className="fa fa-youtube"></i></a></li>
</ul>
    </Col>

    {/* <Col lg="4">
      <h5>Address</h5>
      <p>Opposite Ceremony Banquets, Near Durgadi Bridge,<br/> Kalyan-Thane Highway, Bombai,<br/> Kongaon, Kalyan West 421311  
</p>
<p><i className="fa fa-envelope"></i> <a href="mailto:othgreen197@gmail.com">othgreen197@gmail.com </a></p>
<p><i className="fa fa-mobile"></i> <a href="tel: +91 72084 16741"> +91 7208416741</a> | <a href="tel: +91 72084 16742"> +91 7208416742</a></p>

    </Col> */}

    
  </footer>
    </Container>

   

    <div id="art-side-form"  className={`art-side-form ${isActive ? 'open' : ''} `}   data-closed="1"  style={{display:'block'}}>
    <div className="close" data-toggle="#art-side-form" onClick={handleClick}> ×</div>
    <div id="art-side-form-tab" className="art-side-form-tab"   onClick={handleClick} >
        <div className="art-side-form-side-text">Enquiry now </div>
         
    </div>
    <div className="art-side-form-inner">
        <h3>Contact Us</h3>
        <p>Please Fill The Form To Send Enquiry</p>
                            
<div className="respond form-wrapper">
    
   <ContactMainForm/>
    
 </div>    </div>
</div>
 
  
  
        </div>
    );

}
 
export default Footer;
