import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
   import config from '../constant';
import Sidebar from '../components/layout/sidebar';
import SubHeader from '../components/layout/subheader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
 import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
 import ContactMainForm from '../components/section/contact_main_form';

class Safeenvironment extends Component {
    componentDidMount(){
        document.title = "Safe Environment & Transport | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Safe Environment & Transport ' subtitle=' '/>
            <Container  >
      <Row>
      <Col lg="9">
              
      

             
 
             <Container className='pt-5 mb-5 growSection  lifeAtInovera'>
             <h2  className="blueBg mb-4  text-center"  > Safe Environment & Transport
</h2>
</Container>
<Container className='lifeAtInovera bg-white'>
<Row className='py-4 '>
             <Col lg="5" sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/impact/About-US-Safe-envoirnment.jpg )` 
    }} ><div class="caption">Transport</div></div>
             </Col>
            <Col lg="7" sm="7">
                <p> 
                Safe school transport ensures children travel securely between home and school. It includes well-maintained vehicles, trained drivers, and strict safety protocols. GPS tracking, seat belts, and supervision further enhance security.


</p>
            </Col>
           </Row>
           </Container>
 
           <Container className='lifeAtInovera bg-gray  '>
           <Row className='py-4 '>
 
            <Col lg={{ order:2 , span:5 }} sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/impact/Safe-environment.jpg )` 
    }} ><div class="caption"> Safe Environment</div></div>
             </Col>
            <Col lg={{ order:1 , span:7 }}  sm="7">
            <p>Safety and security at Innovera school are essential to ensure a protected learning environment for students and staff. Measures like CCTV surveillance, secure entry points, and trained security personnel help maintain safety. Regular drills, awareness programs, and a strong anti-bullying policy further enhance a secure atmosphere for everyone.
</p>
            
            </Col>
            </Row> 
           </Container>
 
             
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<Sidebar></Sidebar>

</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>
             <Footer/>


        </div>
    );

}
    }
export default Safeenvironment;