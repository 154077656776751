import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';
import EnrollForm from './enrollmentForm';
import ContactMainForm from './contact_main_form';
import Sidebar from '../layout/sidebar';


class Enroll2 extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Enroll Now' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="py-5  growSection">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
                <Row>
                  
                <Col lg="12">
                <h2  className="blueBg mb-4 text-center "  rounded>Admission </h2>

                <h4>Process For Admission</h4>


                <ul>
                    <li>Our process of admission is very simple and transparent. We strictly adhere all the CBSE norms, while keeping the parents' convenience in mind.
                    </li>
                    <li>We do not encourage admissions based on caste, religion or references.
                    </li>
                    <li>We believe that when a parent is thinking of taking admission for their child in Innovera School, he/she should be convinced with the values and culture of the school.
                    </li>
                    <li>For this reason we always encourage parents to visit our campus and interact with the team. This ensures transparency and effective communication between school and parents.

</li>
                </ul>


                 



                
                </Col>

                </Row>

            <Row>
            <hr></hr>
            
                <EnrollForm/>
            </Row>
                </Container>

            

          
         </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<Sidebar/>


 
 
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default Enroll2;