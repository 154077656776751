import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';
import TeamForm from './teamForm';
import ContactMainForm from './contact_main_form';
import Sidebar from '../layout/sidebar';


export const leaderImages = [
    {
      imgUrl: 'assets/images/team/vinay-joshi.jpg',
      imgCaption:'Vinay Mahesh Joshi',
      desg:' Founder Director',
      linkedIn:'https://www.linkedin.com/in/vinay-mahesh-joshi-85138222'

  },
    {
      imgUrl: 'assets/images/team/vikas_jakune.jpg',
      imgCaption:'Vikas Jakune ',
      desg:'Founder Director ',
      linkedIn:'https://www.linkedin.com/in/vikas-jakune-7b6a013a/'


  },
  {
      imgUrl: 'assets/images/team/4.jpg',
      imgCaption:'Supriya Srivastava',
      desg:'Principal Director',
      linkedIn:'https://www.linkedin.com/in/tr-supriya-srivastava-2a950391/'


  },

  {
    imgUrl: 'assets/images/team/neelam.jpg',
    imgCaption:'Neelam Chakraborty',
    desg:'Academic Director ( EX- DPS Principal)',
    linkedIn:'#'


}
]

// --------------------------------------------

export const academicImages = [
    {
      imgUrl: 'assets/images/team/1.jpg',
      imgCaption:'Prajakta Pawar',
      desg:'Pre School Academic Head'
  },
    {
      imgUrl: 'assets/images/team/2.jpg',
      imgCaption:'Sarita Jha',
      desg:'Junior School Academic Head'

  },
  {
      imgUrl: 'assets/images/team/GaneshGaikwad.jpeg',
      imgCaption:'Ganesh Gaikward',
      desg:' High School Coordinator'

  },
]
// --------------------------------------------

export const adminImages = [
  {
    imgUrl: 'assets/images/team/DV.jpeg',
    imgCaption:'DV Kumar (Retd Brigadier) ',
    desg:'Director Administration'
},  
  {
      imgUrl: 'assets/images/team/savita-patil.jpeg',
      imgCaption:'Savita Patil',
      desg:'Sales and Distribution Head'
  },
     

  {
      imgUrl: 'assets/images/team/amit-rathod.jpeg',
      imgCaption:'Amit Rathod',
      desg:'Office Executive'

  },
]
// --------------------------------------------

export const mentorImages = [
    
    {
      imgUrl: 'assets/images/team/anjali-raikar.jpg',
      imgCaption:'Anjali Railkar',
      desg:'Mentor - Mental Health and Wellness '

  }
 
]
class Career extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='OUR TEAM' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="py-5 text-center growSection ">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Our</span>
            <span className="blueBor"> Team</span>
          </h1> */}
          
                <Row>
                  
                <Col lg="12" >
                <h2  className="blueBg  "  rounded>  Building Innovera </h2>
                </Col>

                </Row>

                </Container>

                <Container fluid  className='team'>
                <Row>        
                {leaderImages.map( (val , i) => (  

              <Col lg='4'  key={i}>
              <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
					        <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
						    <p>{`${val.desg}`} </p> 
               { i  <  3
      ?  <p> <a href={`${val.linkedIn}`}  target="_blank"><i className='fa fa-linkedin'></i></a> </p>
      : null
      
      } 
 					     </div>
				         </div>
              </Col>                         )  )}


                         </Row>
            </Container>


            <Container fluid  className='growSection team'>

            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5"  rounded>Academic Team</h2>
                  </Col>
  
                  </Row>
                <Row>        
                {academicImages.map( (val , i) => (  

              <Col lg='4'  key={i}>
              <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
					        <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
                            <p>{`${val.desg}`} </p>

 					     </div>
				         </div>
              </Col>                         )  )}


                         </Row>
            </Container>


            <Container fluid  className='growSection team d-none'>

            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5"  rounded>Administration and Sales</h2>
                  </Col>
  
                  </Row>
                <Row>        
                {adminImages.map( (val , i) => (  

              <Col lg='4'  key={i}>
              <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
					        <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
                            <p>{`${val.desg}`} </p>

 					     </div>
				         </div>
              </Col>                         )  )}


                         </Row>
            </Container>


            <Container fluid  className='growSection team'>

<Row>
      
      <Col lg="12">
      <h2  className="blueBg text-center  my-5"  rounded>Associates & Mentors</h2>
      </Col>

      </Row>
    <Row>        
    {mentorImages.map( (val , i) => (  

  <Col lg='4'  key={i}>
  <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
                <div className="captionDiv">
                <h3>{`${val.imgCaption}`} </h3>
                <p>{`${val.desg}`} </p>

              </div>
             </div>
  </Col>                         )  )}


             </Row>
</Container>

          
         
         </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">
 

<Sidebar/>
 
 
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default Career;