import React, { Component }  from 'react';
 import Container from 'react-bootstrap/Container';
 import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import Megamenu from '../section/megamenu';
import { Space } from 'antd';
import { Button } from 'react-bootstrap';

   class Header extends Component {
    
    render(){
    return(
      <>

      
<Navbar collapseOnSelect expand="lg" className='newNav'>
      <Container>
        <Navbar.Brand > <NavLink to="/ "> <Image src="assets/images/innovera/new_design/logo.png"  fluid/></NavLink></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav " />
        <Navbar.Collapse id="responsive-navbar-nav  " className='justify-content-center'>
          <Nav className="nav">
             
            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            <NavDropdown title="About us" id="collasible-nav-dropdown"  >

            <NavDropdown.Item href=""> <NavLink to="/values"   activeclassname="active">Values </NavLink> 
            </NavDropdown.Item>


          

            <NavDropdown title="Family" id="values-dropdown">
              <NavDropdown.Item as={NavLink} to="/team" activeclassname="active">
               Building Innovera 
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/careers" activeclassname="active">
              Join the Family
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/refer_form" activeclassname="active">
              Refer a student
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown.Item href=""> <NavLink to="/our-school"   activeclassname="active">Our School </NavLink></NavDropdown.Item>
            

              
              
            </NavDropdown>


            <NavDropdown title="Life at Innovera" id="collasible-nav-dropdown2"  >

  <NavDropdown title="Why Innovera " id="values-dropdown2">
  <NavDropdown.Item href=" "><NavLink to="/facilities"   activeclassname="active">Infrastructure /Facilities </NavLink></NavDropdown.Item>

  <NavDropdown.Item href=" "><NavLink to="/safe-environment"   activeclassname="active">Safe environment and  transport </NavLink></NavDropdown.Item>

  <NavDropdown.Item href=" "><NavLink to="/why-innovera"   activeclassname="active">parents involvement  </NavLink></NavDropdown.Item>

            </NavDropdown>
            
            
<NavDropdown title="Day at Innovera " id="values-dropdown3">
<NavDropdown.Item href=""> <NavLink to="/academics"   activeclassname="active">Academics
                </NavLink> </NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/beyond-academics"   activeclassname="active">Beyond Academics
              </NavLink> </NavDropdown.Item>


              <NavDropdown.Item href=""> <NavLink to="/gallery"   activeclassname="active">Gallery </NavLink></NavDropdown.Item>
            </NavDropdown>
              
            <NavDropdown.Item href=""> <NavLink to="/impact"   activeclassname="active">Our initiatives  </NavLink></NavDropdown.Item>                   
   
            </NavDropdown>


            
              
            
             
          
            
            <NavDropdown title="Contact Us" id="collasible-nav-dropdown"  >
               <NavDropdown.Item href="">
               <NavLink to="/contact"   activeclassname="active">Contact now </NavLink> 
              </NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/our-branches"   activeclassname="active">Our Branches </NavLink> </NavDropdown.Item>
              <NavDropdown.Item href=" "> <NavLink to="/visit"   activeclassname="active">Schedule a Visit</NavLink> 
              </NavDropdown.Item>
             
                    
            </NavDropdown>
          </Nav>

         
          <Nav>
          {/* <NavLink to="/"   activeclassname="active">Home</NavLink> */}
         
          
          <NavLink to="/enroll"   activeclassname="active" ><Button className="btn btn-success">Admissions Open 2025-26</Button>
          </NavLink>
          {/* <NavLink to="/academics"   activeclassname="active">Academics</NavLink>
          <NavLink to="/facilities"   activeclassname="active">Facilities</NavLink>
          <NavLink to="/impressions"   activeclassname="active">Impressions</NavLink>
             <NavLink to="/about"   activeclassname="active">About us</NavLink>
             <NavLink to="/team"   activeclassname="active">Our Team</NavLink>
             <NavLink to="/careers"   activeclassname="active">Careers</NavLink>
             <NavLink to="/contact"   activeclassname="active">Contact Us</NavLink> */}
            

{/* <NavDropdown title="Login" id="collasible-nav-dropdown" className='' >

 
    <NavDropdown.Item href="https://erp.innoveraschool.com/site/login " target="_blank">Staff</NavDropdown.Item>
    <NavDropdown.Item href="https://erp.innoveraschool.com/site/userlogin" target="_blank" >
    Student/Parent
    </NavDropdown.Item>
     
  </NavDropdown> */}
          </Nav>
 
        </Navbar.Collapse>
      </Container>

      <a href="https://erp.innoveraschool.com/site/userlogin" target="_blank"><span className="blue stickyLink">Parent Login</span></a>
      {/* <span className="orange stickyLink">Admission Open</span> */}
      <NavLink to="/enroll"   activeclassname="active" ><span className="green stickyLink">Enquire Now</span></NavLink>
    </Navbar>

    <a href="https://api.whatsapp.com/send?phone=+919168290611&amp;text=Hello." class="float effetMoveGradient " target="_blank"><i class="fa fa-whatsapp my-float "></i></a>
    </>
           );
}

  }
export default Header;
