import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'


const cbse = [
    {
        heading:"Permission Letters",
        backend:"View Permission Letters"
    },
    {
        heading:"Permission letter 2",
        backend:"View Permission letter 2"
    },
    {
        heading:"NOC",
        backend:"View NOC"
    },
    {
        heading:"School Society Permission Letters",
        backend:"View School Society Permission Letters"
    },
    {
        heading:"EPTA",
        backend:"View EPTA"
    }
]
class Newsltr extends Component {
        render() {

    return(
        <div>
            <Container className="py-5 text-center">
            <h1 className=" pb-5  mt-0 mb-0">
             <span className="blueBor"> School Newsletters</span>
          </h1>            
                 <Row>
                    <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_April.pdf' target="_blank"> 
                    <div className="circular-sb">
                    School Newsletter April
                        <span className="circDesc"> School Newsletter April</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div> 
                        </a>
                    </Col>
                    <Col lg="6">
                    <a href='assets/images/innovera/newsletter/School_Newsletter_May.pdf' target="_blank"> 
                    <div className="circular-sb">
                    School Newsletter May
                        <span className="circDesc"> School Newsletter May</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div> 
                        </a>
                         </Col>
                    <Col lg="6"> 
                    <a href='assets/images/innovera/newsletter/School_Newsletter_June.pdf' target="_blank"> 
                    <div className="circular-sb">
                    School Newsletter June
                        <span className="circDesc"> School Newsletter June</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                        </div>
                        </a>
                    </Col>
                
                <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_July.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter July
                        <span className="circDesc">School Newsletter July
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>
              

                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_August.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter August
                        <span className="circDesc">School Newsletter August
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>

                     
                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_September.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter September
                        <span className="circDesc">School Newsletter September
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>

                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_October.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter October
                        <span className="circDesc">School Newsletter October
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>

                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_November.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter November
                        <span className="circDesc">School Newsletter November
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>

                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_December.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter December
                        <span className="circDesc">School Newsletter December
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>

                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_January.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter January
                        <span className="circDesc">School Newsletter January
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>


                     <Col lg="6">
                <a href='assets/images/innovera/newsletter/School_Newsletter_February.pdf' target="_blank"> 
                <div className="circular-sb">
                School Newsletter February
                        <span className="circDesc">School Newsletter February
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>
                </Row>



                


            </Container>
        </div>
    );
}
    }
export default Newsltr;