import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
 import SubHeader from '../layout/subheader';
 import { NavLink } from 'react-router-dom';
 import Nav from 'react-bootstrap/Nav';
  import EnquiryForm from './enquiryform';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
   
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const ValueForm = () => {
 
       
        const [double, setDouble] = useState(false);


        const [mailerState, setMailerState] = useState({
            type: "", // Added type field for radio button

          message: "",
        });
    
      

         // ✅ Handle state change for inputs & radio buttons
  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setMailerState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
    
        const submitEmail = async (e) => {
           e.preventDefault();
          //console.log({ mailerState });
          setDouble(true);
          const response = await fetch(config.BASE_URL+"valueform/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");
                toast.success("Message Sent!" , {autoClose: 2000})

              } else if (resData.status === "fail") {
               // alert("Message failed to send");
                toast.success("Message failed to send!", {autoClose: 2000})

              }
            })
            .then(() => {
              setMailerState({
                
                type: "",
                 message: "",
              });
              setDouble(false);

            });
            
        };
    
         return(


          <div>
              
            
              <Container className="  ">
              <ToastContainer />

                <Row>
                    <Col lg="12">
                     

                        <Form  onSubmit={submitEmail}
>
<Form.Group className="mb-3">
  <Form.Label><b>Which design you liked more?</b></Form.Label>
  <div>
    <Form.Check
      type="radio"
      label="Dil Se Banaya Hua"
      name="type"
      value="old"
      onChange={handleStateChange}
      checked={mailerState.type === "old"}
      inline

    />
    <Form.Check
      type="radio"
      label="Dimag Se Banaya Hua"
      name="type"
      value="new"
      onChange={handleStateChange}
      checked={mailerState.type === "new"}
      inline

    />
  </div>
  </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={handleStateChange}
           name="message"     value={mailerState.message}/>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit"  disabled={double}     className='btn btn-success'  >    Submit

    </button>
                            </Form.Group>

                            </Form>
                    </Col>
                     </Row>
                     </Container>
 
         </div>
            
         )
    }


export default ValueForm;