import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
   import config from '../constant';
import Sidebar from '../components/layout/sidebar';
import SubHeader from '../components/layout/subheader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
 import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
 import ContactMainForm from '../components/section/contact_main_form';

class OurSchool extends Component {
    componentDidMount(){
        document.title = "Our School | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Our School ' subtitle=' '/>
            <Container  >
      <Row>
      <Col lg="9">
              
      

             
 
             <Container className='pt-5 mb-5 growSection  lifeAtInovera'>
             <h2  className="blueBg mb-4  text-center"  > Our School
</h2>
</Container>
<Container className='lifeAtInovera bg-white'>
<Row className='py-4 '>
             <Col lg="5" sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/impact/About-US-Our-School.jpg )` 
    }} ><div class="caption"> Foundational Years <br></br>Preschool to Class 2</div></div>
             </Col>
            <Col lg="7" sm="7">
                <p><b>A Nurturing Start For The Little Ones</b><br></br>
The early years shape a child's cognitive, social, and emotional growth. At Innovera School, we nurture young minds (Preschool to Class 2) through a safe, engaging environment. Our hands-on, experiential learning fosters curiosity, creativity, and critical thinking, building a strong foundation for future success.


</p>
            </Col>
           </Row>
           </Container>
 
           <Container className='lifeAtInovera bg-gray  '>
           <Row className='py-4 '>
 
            <Col lg={{ order:2 , span:5 }} sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/impact/About-US.jpg )` 
    }} ><div class="caption"> Preparatory Years <br></br>(Class 3 to Class 5)</div></div>
             </Col>
            <Col lg={{ order:1 , span:7 }}  sm="7">
            <p><b>Building Foundations for Academic Excellence
            </b><br></br>
At Innovera School, the Preparatory Years (Class 3 to 5) bridge foundational learning and structured academics. Our curriculum fosters critical thinking, creativity, and curiosity through interactive and experiential learning. We emphasize an engaging and interactive approach that encourages students to explore, inquire, and apply their knowledge in meaningful ways.
</p>
            
            </Col>
            </Row> 
           </Container>
 
           <Container className='lifeAtInovera bg-white  '>

           <Row className='py-4  '>
 
             <Col lg="5" sm="5" >

             <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/impact/6-to-9.jpg )` 
    }} ><div class="caption"> Middle Years <br></br>(Class 6 to Class 8)</div></div>
             </Col>
            <Col lg="7" sm="7" >
                <p><b>Empowering Minds, Shaping Futures</b><br></br>
At Innovera School, the Middle Years (Class 6 to 8) are pivotal, connecting foundational learning to advanced academics. Our curriculum challenges and inspires students, fostering intellectual curiosity, leadership, and self-discipline in an engaging and supportive environment. We aim to empower young minds with knowledge, skills, and confidence while nurturing their individuality.

</p>
            </Col>
          </Row>  
          </Container>
          <Container className='lifeAtInovera bg-gray'>

           <Row className='py-4'>
 
            <Col lg={{ order:2 , span:5 }} sm="5" >
            <div class="whyChImage" style={{ 
      backgroundImage: `url( assets/images/innovera/impact/Class-9-10.jpg )` 
    }} ><div class="caption">Secondary Years <br></br>(Class 9 to Class 10)</div></div>
             
            </Col>
            <Col lg={{ order:1 , span:7 }}  sm="7">
            <p><b>Preparing For Future Success </b><br></br>
At Innovera School, the Secondary Years (Class 9 to 10) are pivotal, laying the foundation for higher education and career goals. We provide a rigorous yet supportive environment, focusing on in-depth subject knowledge, analytical thinking, and problem-solving skills. Through structured learning, personalized guidance, and hands-on application, we prepare students to excel in examinations, future academic challenges and beyond. We empower students to achieve their fullest potential.
</p>
            </Col>
          </Row> 
</Container>


  
  
      
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<Sidebar></Sidebar>

</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>
             <Footer/>


        </div>
    );

}
    }
export default OurSchool;