import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image from 'react-bootstrap/Image'

 export const images2 = [
    {
        imgUrl: 'assets/images/innovera/new_design/foster-talk.png',
        imgCaption:'foster talks'
    },
    {
        imgUrl: 'assets/images/innovera/new_design/Music.png',
        imgCaption:'Music'
    },
    {
        imgUrl: 'assets/images/innovera/new_design/Sports.png',
        imgCaption:'Sports'
    }
]
const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    navText: ['<img src="assets/images/hotel/Left-Arrow.png">', '<img src="assets/images/hotel/Right-Arrow.png">'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};
    class Owlslider2 extends Component {
    
    render() {
    return(
        <div className='About '  >
        <Container className="py-4">
             <Row className="justify-content-center text-center">
            {/* <Col xs lg="6">1 of 2</Col> */}
            <Col xs lg="12">
        <OwlCarousel className='owl-theme'  {...options}>
        
                
    <div className='item' >
     <div className='imageDiv2 ' style={{ 
      backgroundImage: `url( assets/images/innovera/impact/Indian_Ness.jpg )` 
    }}></div>
<div className="productDiv2 orangeBB"  >
   
					        <div className="captionDiv2">
						    <h3>Indianness at School </h3>
                            <p>Innovera School fosters Indian values like respect, discipline, and unity through yoga, moral education, and festival celebrations, instilling cultural pride. It nurtures empathy, integrity, and responsibility to shape well-rounded citizens.</p>
                            <a href="#"><i class="fa fa-long-arrow-right"></i></a>
 					     </div>
				         </div>
                         </div>

                         <div className='item' >
     <div className='imageDiv2' style={{ 
      backgroundImage: `url( assets/images/innovera/impact/safety.jpg )` 
    }}></div>
<div className="productDiv2 greenBB "  >
   
					        <div className="captionDiv2">
						    <h3>Safety and Security  </h3>
                            <p>Innovera School ensures safety with CCTV, secure entry points, trained staff, and strict anti-bullying policies, creating a protected learning environment.  </p>
                            <a href="#"><i class="fa fa-long-arrow-right "></i></a>
 					     </div>
				         </div>
                         </div>

                         <div className='item' >
     <div className='imageDiv2' style={{ 
      backgroundImage: `url( assets/images/innovera/impact/Approcable.jpg )` 
    }}></div>
<div className="productDiv2 blueBB"  >
   
					        <div className="captionDiv2">
						    <h3>Approachable </h3>
                            <p>We live on our values and they are the guiding force for everything that we do       with all the stakeholders. It doesn't stop at us but also reflects greatly in the actions and words of our school team and children.
                            </p>
                            <a href="#"><i class="fa fa-long-arrow-right"></i></a>
 					     </div>
				         </div>
                         </div>

                          

                         
                       
</OwlCarousel>
</Col>
</Row>
</Container>
</div>
    );
}

}



export default Owlslider2;