import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
  import DatePicker from "react-datepicker";
 import format from "date-fns/format";
 import "react-datepicker/dist/react-datepicker.css";
 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 
   
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const EnrollForm = () => {
 
        const [double, setDouble] = useState(false);

        const [birthDate, setbirthDate] = useState(new Date().toLocaleDateString('fr-FR'));
    

        const [mailerState, setMailerState] = useState({
          name: "",
          phone:"",
          email: "",
          child_name:"",
          previous_school:"",
          class_studying:"",
          have_lc:"",
          apply_class:"",
          
        });
    
        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
    
        const submitEmail = async (e) => {
           e.preventDefault();
           setDouble(true);

          //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"apply_send/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState , birthDate }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");

                toast.success("Message Sent!" , {autoClose: 2000})

 
              } else if (resData.status === "fail") {
               // alert("Message failed to send");
                toast.success("Message failed to send!", {autoClose: 2000})

              }
            })
            .then(() => {
              setMailerState({
                email: "",
                name: "",
                phone:"",
                child_name:"",
                previous_school:"",
                class_studying:"",
                have_lc:"",
                apply_class:"",

              });
              setDouble(false);

 
            });
            
        };
    
         return(
             <Container className="">
              <ToastContainer />

                <Row>
                    <Col lg="12">
                    
                        <Form  onSubmit={submitEmail}
>                       <Row>
                    <Col lg='4' xs='12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Parents' name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="Parents' name " onChange={handleStateChange}
 name="name"  value={mailerState.name} />
                            </Form.Group>
                            </Col>
                            <Col  lg='4' xs='12'>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="phone"  value={mailerState.phone} placeholder="88888 55555" />
                            </Form.Group>
                            </Col>
                            <Col  lg='4' xs='12'>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" required placeholder="name@example.com" onChange={handleStateChange}
           name="email"
           value={mailerState.email} />
                            </Form.Group>
                            </Col>

                            </Row>
                          

                           <Row>
                            <Col  lg='4' xs='12'> 
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Child name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="Child name" onChange={handleStateChange}
 name="child_name"  value={mailerState.child_name} />
                            </Form.Group>
                            </Col>
                            <Col  lg='4' xs='12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Date of birth</Form.Label>
                                 
 <DatePicker
        //selected={birthDate} 
          //  onChange={(date) => setbirthDate(date)}    
           
           onChange={(date) => {
            const d = new Date(date).toLocaleDateString('fr-FR');
            console.log(d);
            setbirthDate(d);
          }}
               selectsStart
        birthDate={birthDate}
        name="start_date"
        dateFormat="dd/MM/yyyy"
        className="form-control"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        value={birthDate}
         required
        autoComplete='off'
        placeholderText={'Please select a date'} 

       />
                            </Form.Group>
                            </Col>
                            <Col  lg='4' xs='12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label> Current school</Form.Label>
                                <Form.Control type="text"  onChange={handleStateChange}
 name="previous_school"  value={mailerState.previous_school} placeholder=" Current school" />
                            </Form.Group>
                            </Col>
                           </Row>

                           <Row>
                            {/* <Col  lg='4' xs='12'> 
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Do you have LC </Form.Label>
                                <Form.Select value={mailerState.have_lc} name="have_lc" aria-label="Default select example" onChange={handleStateChange}>

                                  <option value=" ">Select Any Option</option>
                                 <option value="Yes" >Yes</option>
                                 <option value="No"  >No</option>
                                
                                </Form.Select>


                            

                            </Form.Group>
                            </Col> */}
                            {/* <Col  lg='4' xs='12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Class studying</Form.Label>
                                <Form.Control type="text" required     onChange={handleStateChange}
 name="class_studying"  value={mailerState.class_studying} placeholder="Class studying" />
                            </Form.Group>
                            </Col> */}
                            <Col  lg={{span:4}}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Applying for which class</Form.Label>
                                <Form.Control   onChange={handleStateChange}
 name="apply_class"    value={mailerState.apply_class} placeholder="Applying for which class" />
                            </Form.Group>
                            </Col>
                           </Row>

                        
                             <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit" disabled={double} className='btn btn-success'  >    Submit

    </button>
                            </Form.Group>

                            </Form>
                    </Col>
                   
                </Row>
            </Container>

            
         )
    }


export default EnrollForm;