import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import Sidebar from '../layout/sidebar';



class Infrastructure extends Component {
        render() {

    return(
        <div>
             <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Facilities' subtitle=''/>
            <Container  >
      <Row>
      <Col lg="9">
              

             
 
             <Container className='pt-4 mb-5 lifeAtInovera'>
             
          <Row className='py-4'>
            <Col lg="6" sm="6" >

            <Card>
                    <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Classroom-Infra.jpg" />
                    <Card.Body>
                        <Card.Title> Infrastructure  </Card.Title>
                        <Card.Text>
                        Our school features spacious classrooms, a lab, library, and resource room.


                        </Card.Text>
                    </Card.Body>
                    </Card>
            
            </Col>
           
          
            <Col lg="6"  sm="6" >
            <Card>
                    <Card.Img variant="top" src="assets/images/innovera/impact/Lab Facilites.jpg" />
                    <Card.Body>
                        <Card.Title> Laboratory </Card.Title>
                        <Card.Text>
                        A well-equipped space for hands-on science experiments and learning.


                        </Card.Text>
                    </Card.Body>
                    </Card>
            
            </Col>
           
              
 
 
           <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Ground-Infra.jpg" />
                    <Card.Body>
                        <Card.Title> Play ground </Card.Title>
                        <Card.Text>
                        Play ground of approx 8000 sq m and an open hall for sports and social activities.
                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>
           
            <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Big-Hall-Infra.jpg" />
                    <Card.Body>
                        <Card.Title>Multipurpose Hall </Card.Title>
                        <Card.Text>
                        A versatile space for events, sports, and activities.

                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>
            
        
 
  
  <Col  lg="6" sm="6" >
   <Card>
          <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Library-Infra-1.jpg" />
          <Card.Body>
              <Card.Title> Library  </Card.Title>
              <Card.Text>
              One of the most loved places for children with thousands of age-appropriate books.          </Card.Text>
          </Card.Body>
  </Card>
  </Col>

  <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Music-Room.jpg" />
                    <Card.Body>
                        <Card.Title>Music and Dance Room
                        </Card.Title>
                        <Card.Text>
                        Equipped with musical instruments, our choir and dance group actively participate and win accolades
                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>



            <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Complab-Infra.jpg" />
                    <Card.Body>
                        <Card.Title>Computer lab</Card.Title>
                        <Card.Text>
                        Well Equipped computer lab with state of art set up, that gives enough room for all the children to learn through exploration.
                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>

            <Col lg="6" sm="6" >
             <Card>
                    <Card.Img variant="top" src="assets/images/innovera/2024/New_Web_Inner_Images/Image-2.jpg" />
                    <Card.Body>
                        <Card.Title>Smartclass</Card.Title>
                        <Card.Text>
                        Interactive digital classrooms enhancing learning through technology.

                        </Card.Text>
                    </Card.Body>
            </Card>
            </Col>
   
</Row> 
            </Container>
      
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<Sidebar/>
</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>

         </div>    );
}
    }
export default Infrastructure;