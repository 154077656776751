import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import { Link } from "react-scroll";
import Sidebar from '../layout/sidebar';


class Grow extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Grow' subtitle='with us'/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="py-5 text-center growSection">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
                <Row>
                  
                <Col lg="12">
                <h2  className="blueBg mb-4  "  rounded>Do You Have a Dream To Start a Pre-school or a High School?</h2>

                <h4>Associate with us and we will help you achieve your dream which may be, franchising, consultancy or partnering. </h4>

                
                </Col>

                </Row>

                </Container>

                <Container fluid  className='growthDivdd'>
                <Row>        
              <Col lg="6" >
              <NavLink to="/franchise" activeclassname="active">
              <div className="growthDiv">
                <div
                  className="growthImg1"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-5.jpg)`,
                  }}
                >
                  <div className="growthCaption p-3 text-center">
                     <h4 className="text-center   mainText "  ><span>Do you have a land you want to lease us? </span></h4> 
                    <div   className="btn btn-success text-center" >Connect with us</div>
                  </div>
                </div>
              </div>
              </NavLink>
                {/* <div className='BranchBox'>
              <h3 className="text-center blink mainText "  ><span>Do you have a land you want to lease us ? </span></h3>

                <h4  className="text-center  ">  Connect with us    </h4>
                    
                </div> */}
              </Col>
              
              <Col>
              <NavLink to="/franchise" activeclassname="active">
              <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-6.jpg)`,
                  }}>
                   <div className="growthCaption p-3 text-center">
                  <h4 className="text-center  mainText "  ><span>Do you have passion but no resources?  </span></h4>
                    <div  className="btn btn-success text-center" >We will help you grow</div>
                  </div>
                </div>
              </div>
              </NavLink>
              {/* <div className='BranchBox3'>

              <h3 className="text-center blink mainText "  ><span>Do you have passion but no resources ?  </span></h3>

            <h4 className="text-center  ">  We will help you grow    </h4>
          </div> */}

              </Col>
                        </Row>
            </Container>

          <div className="highlights py-5" id="connectivity">

            <Container>
                <EnquiryForm/>
             </Container>
            <Container >

            <h1 className=" py-5  mt-0 mb-0">
           
            <span className="blueBg">Advantages of</span>
            <span className="greenBor"> growing with Innovera</span>
          </h1>
                <Row>
     <div class="col-sm-4">
        
        <div className="iconBox">
         <Image src="assets/images/innovera/Green-icon-1.png" fluid/>

            <p>
            More than a Decade of Experience 	

            </p>
        </div>

    </div>
    <div className="col-sm-4">
        
           <div className="iconBox">
         <Image src="assets/images/innovera/Green-icon-1.png" fluid/>

            <p>
            Dedicated Support	

            </p>
        </div>
        </div>
        <div className="col-sm-4">
        
        <div className="iconBox">
      <Image src="assets/images/innovera/Green-icon-1.png" fluid/>

         <p>
         Proven Business Model 	

         </p>
     </div>

 </div>
                </Row>
                <Row>
     <div class="col-sm-4">
        
        <div className="iconBox">
         <Image src="assets/images/innovera/Green-icon-1.png" fluid/>

            <p>
            Committed to Grow	


            </p>
        </div>

    </div>
    <div className="col-sm-4">
        
           <div className="iconBox">
         <Image src="assets/images/innovera/Green-icon-1.png" fluid/>

            <p>
            Robust Techincal/Data  Support		

            </p>
        </div>
        </div>
        <div className="col-sm-4">
        
        <div className="iconBox">
      <Image src="assets/images/innovera/Green-icon-1.png" fluid/>

         <p>
         Defined Systems and Processes	

         </p>
     </div>

 </div>
                </Row>
            </Container>
            </div>
 
         
         </Col>
         <Col lg="3">
 
 <Sidebar/>


 
  
         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default Grow;