import React, { Component ,useState, useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import Growth from '../components/section/growth';
import NewsLetter from '../components/section/newsletter';
import Prepare from '../components/section/prepare';
import config from '../constant';
import AboutHomepage from '../components/section/abouthomepage';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import Megamenu from '../components/section/megamenu'; 
import Accordion from 'react-bootstrap/Accordion';

import { Space } from 'antd';
import Container from 'react-bootstrap/Container';
 import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
import Owlslider2 from '../components/section/owlslider2';
import './Footer.css';
import StickyRightButtonWithModal from './stickyBtn';

    const NewHome = () => {
      const footerStyle = {
        backgroundColor: '#f3f6fb',
        color: '#000!important',
        padding: '20px',
        textAlign: 'center',
      };
    
    // componentDidMount(){
    //     document.title = "Home | " + config.SIE_NAME
    //   }

    // const [displayPopUp, setDisplayPopUp] = useState(true);

    // // when pop-up is closed this function triggers
    // const closePopUp = () => {
    //   // setting key "seenPopUp" with value true into localStorage
    //   localStorage.setItem("seenPopUp", true);
    //   // setting state to false to not display pop-up
    //   setDisplayPopUp(false);
    // };
  
    // // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
    // useEffect(() => {
    //   // getting value of "seenPopUp" key from localStorage
    //   let returningUser = localStorage.getItem("seenPopUp");
    //   // if it's not there, for a new user, it will be null
    //   // if it's there it will be boolean true
    //   // setting the opposite to state, false for returning user, true for a new user
    //   //setDisplayPopUp(!returningUser);
    //   setDisplayPopUp(returningUser);

    // }, []);



    // State to control the modal visibility
 const [showModal, setShowModal] = useState(true);

 // Function to close the modal
 const handleClose = () => setShowModal(false);
  // useEffect to open the modal on page load
  useEffect(() => {
    // Show the modal on mount
    setShowModal(true);
  }, []); // Empty dependency array ensures this effect runs only once on mount


    return(
        <div style={{backgroundColor:"#fff"}}>

<HelmetProvider>
             <meta charSet="utf-8" />
                <title> Innovera School - Best CBSE school in Hadapsar | Magarpatta</title>
	<meta name="description" content="Innovera School is a leading CBSE school in Hadapsar and Magarpatta. We provide best education with latest teaching and learning methods." />
	<link rel="canonical" href="https://innoveraschool.com/?PSE" />   
   </HelmetProvider>


   <Header/>
 
    {/* <div style={{ 
      backgroundImage: `url( assets/images/innovera/new_design/Banner.jpg )` 
    }} className='BannerNew'>

        <div className="container">
            <div className='bluePatch'>
                    <h1>Welcome <br></br><span className='yellow'>to the school values</span></h1>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.  </p>
            </div>
        </div>
    </div> */}

    {/* <img src="assets/images/innovera/new_design/Innovera-Banner.webp" class="img-fluid d-none d-sm-block" alt="Innovera School"/>

      <img src="assets/images/innovera/new_design/Innovera-banner-Mobile.webp" class="img-fluid d-block d-sm-none" alt="Innovera School"/> */}

<Slider></Slider>
   <Container  className='py-5 USP d-none'>
        <Row>
            <Col lg="4" className='d-flex justify-content-center align-items-center'>
                <h2 className='mainFont'>Our USPs</h2>
            </Col>

            <Col lg="8">
                <Row className="mb-4">
                <Col lg="6"> 
                    <div className='shapdeDiv '>
                        <div className='borderLeft blueBorder'></div>
                        <img src='assets/images/innovera/new_design/1-Integrated-STEM.png' className='img-fluid' />
                        Integrated STEM
                    </div>
                </Col>
                <Col lg="6">
                   <div className='shapdeDiv  '>
                   <div className='borderLeft orangeBorder'></div>
                        <img src='assets/images/innovera/new_design/3-Personal-Attention.png' className='img-fluid' />
                        Integrated STEM

                    </div>
                 </Col>

                </Row>

                <Row className="mb-4">
                    <Col lg="6"> 
                       <div className='shapdeDiv  '>
                       <div className='borderLeft greenBorder'></div>
                            <img src='assets/images/innovera/new_design/2-Technology-Driven.png' className='img-fluid' />
                            Integrated STEM

                        </div>
                    </Col>
                    <Col lg="6">  
                        <div className='shapdeDiv  '>
                        <div className='borderLeft blueBorder'></div>
                            <img src='assets/images/innovera/new_design/Inclusive-Environment.png' className='img-fluid' />
                            Integrated STEM

                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>

    <div className=" text-center  ">
  <div className="container my-5">
    <div className='manSection row'>
      <div className='col-sm-3'>
      <img src='assets/images/innovera/new_design/last-section-kid.png' className='img-fluid man-img'></img>
      </div>

      <div class="col-sm-9  ">
    <h2>Admissions Open 2025-26</h2>
    
     <NavLink to="/enroll"   activeclassname="active" ><button className='whiteBtn btn'>Apply Now</button></NavLink> 
    </div>
</div>
</div>
</div>
    <div className='aboutTheSchool'  style={{ 
      backgroundImage: `url( assets/images/innovera/new_design/bg.png )` 
    }} >
        <Container>
            <Row>
                <Col lg="6"> 

                    <img src='assets/images/innovera/new_design/Manan.png' className='img-fluid'></img>
                 </Col>
                <Col lg="6"> 
                <h2 className='mainFont'>About the school</h2>
                <p>Innovera School has been providing quality education in the Hadapsar area for the past 10 years. Our beautiful campus is equipped with modern facilities, creating a great learning environment. We focus on academic excellence while nurturing students holistically. Our dedicated teachers ensure children grow intellectually, socially, emotionally, and physically. We emphasize not just academics but also sports and co-curricular activities. Inspiration, appreciation, and confidence-building are at the core of our teaching approach. Our dynamic educators transform enthusiastic learners into achievers and global citizens. We strongly believe in active collaboration between parents and the school. Open communication with parents is encouraged for holistic student development. At Innovera, we shape bright minds for a successful future.</p>
                <Accordion defaultActiveKey="0" className='mt-4 d-none'>
      <Accordion.Item eventKey="0" className='greenB'>
        <Accordion.Header>Adhyayan (Study)</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1"  className='orangeB'>
        <Accordion.Header>Manan</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='blueB'>
        <Accordion.Header>Chintan (Reflection)</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

                 </Col>
            </Row>


            <Row className='mt-5 d-none'>
                <Col lg="12">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, tenetur sequi? Rem explicabo exercitationem assumenda ut minima nam distinctio sunt, quod quo doloremque blanditiis laudantium mollitia voluptatibus at aliquam earum Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, tenetur sequi? Rem explicabo exercitationem assumenda ut minima nam distinctio sunt, quod quo doloremque blanditiis laudantium mollitia voluptatibus at aliquam earum?</p>
                </Col>
            </Row>
        </Container>
    </div>


<div className="keyPrograms" style={{ 
      backgroundImage: `url( assets/images/innovera/new_design/work_bg.36bd86b8.jpg )` 
    }} >
    <Container  className='py-5 '>

        <Row>
            <Col lg="12 text-center">
            <h2 className='mainFont'>Innovera is known for </h2>
            <Owlslider2></Owlslider2>
             </Col>
        </Row>
    </Container>

</div>

<div>
<div className=" text-center  d-none">
  <div className="container ">
    <div className='manSection row'>
      <div className='col-sm-3'>
      <img src='assets/images/innovera/new_design/last-section-kid.png' className='img-fluid man-img'></img>
      </div>

      <div class="col-sm-9  ">
    <h2>Admissions Open</h2>
    
    <button className='whiteBtn btn'>Apply Now</button>
    </div>
</div>
</div>
</div>
  </div>
        <Footer  style={footerStyle}/>

      <StickyRightButtonWithModal />


        {/* <Modal show={displayPopUp} onHide={closePopUp}> show={showModal}*/ }
        <Modal  onHide={handleClose}>

         <Modal.Body><Image src="assets/images/innovera/Innovera-WA.jpg" fluid />
         <h4 className='text-center mt-3'><a href="https://quiz.mi-school.org/index.php?bid=9"  target="_blank">Click here to join!!</a></h4>
         </Modal.Body>
         <Modal.Footer>
           {/* <Button variant="secondary" onClick={closePopUp}> */}
           <Button variant="secondary" onClick={handleClose}>
             Close
           </Button>
           
         </Modal.Footer>
       </Modal>
         </div>
    );
 
}
export default NewHome;