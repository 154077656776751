import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import ErrorPage from './pages/404';
import About from './pages/about';
import Contact from './pages/contact';
import Speciality from './pages/speciality';
import Attraction from './pages/attraction';
import React, { Component }  from 'react';
import MainValues from './pages/values';
 import GrowMain from './pages/grow_main';
import CbseMain from './pages/cbseMain';
import BeyondMain from './pages/beyond_main';
import ParentsMain from './pages/parentsMain';
import FacultyMain from './pages/facultiesMain';
import WhyInnoveraMain from './pages/whyInnoveraMain';
import BranchMain from './pages/branchesMain';
import ApplyMain from './pages/applyMain';
import VisitMain from './pages/visitMain';
import LifeInnoveraMain from './pages/lifeAtinnovera';
import InfraMain from './pages/InfrastructureMain';
import CareerMain from './pages/careerMain';
import ImpactMain from './pages/impactMain';
import TeamMain from './pages/teamMain';
 import EnrollMain from './pages/enrollMain';
import AcademicsMain from './pages/academicsMain';
import ImpressMain from './pages/imppressionMain';
import ScrollToTop from './ScrollToTop';
import InspireMain from './pages/deliver_inspMain';
import ExpoPrep from './pages/imp_expo';
import Expo21 from './pages/imp_expo_2021';
import GarbaNight from './pages/imp_garba_night';
import RakhiMaking from './pages/imp_rakhi_making';
import Bhondla from './pages/imp_bhondla';
import Puberty from './pages/imp_puberty';
import NightCamp from './pages/imp_night_cmp';
import FieldTrip from './pages/imp_field_trip';
import AnnualSport from './pages/imp_annual_sport';
import Foster from './pages/imp_foster';
import Library from './pages/imp_library';
import Friendship from './pages/imp_friendship';
import Gettogether from './pages/imp_gettogether';
import Constellation from './pages/imp_constel';
import PrivacyPolicy from './pages/privacy_policy';
import ChildrenDay from './pages/imp_children_day';
import SST from './pages/imp-sst-labs';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Franchise from './pages/franchiseForm';
import VisitMainform from './components/section/visitForm';
import Expo23 from './pages/imp_expo23';
import MumbaiPicnic from './pages/imp_mumbaipicnic';
import ReferMain from './pages/refer_main';
import Terms from './pages/terms';
import NewsLetterMain from './pages/newsletterMain';
import CurrentOpeningsMain from './pages/currentOpeningsMain';
import NewHome from './pages/newHome';
import Constellation24 from './pages/imp_constellation_24';
import Expo24 from './pages/expo24';
import Independance24 from './pages/indepenence_day_24';
import Mumbai24 from './pages/mumbai-trip-24';
import GrandP24 from './pages/grand_parents_24';
import Sports24 from './pages/sports_day_pre';
import OurSchool from './pages/our_school';
import Safeenvironment from './pages/safe_envoirnment';

function App() {
  return (
   <BrowserRouter>
      <ScrollToTop />

    <Routes>
      <Route path="/" element= {<NewHome />} />
      <Route path="/home" element= {<NewHome />} />
      <Route path="/contact" element= {<Contact />} />
      <Route path="/about" element= {<About/>} />
      <Route path="/speciality" element= {<Speciality/>} />
      <Route path="/attraction" element= {<Attraction/>} />
      <Route path="/values" element= {<MainValues/>} />
      <Route path="/grow-with-us" element= {<GrowMain/>} />
      <Route path="/school-docs" element= {<CbseMain/>} />
      <Route path="/beyond-academics" element= {<BeyondMain/>} />
      <Route path="/parents" element= {<ParentsMain/>} />
      <Route path="/faculties" element= {<FacultyMain/>} />
      <Route path="/why-innovera" element= {<WhyInnoveraMain/>} />
      <Route path="/our-branches" element= {<BranchMain/>} />
      <Route path="/apply" element= {<ApplyMain/>} />
      <Route path="/visit" element= {<VisitMain/>} />
      <Route path="/life-at-innovera" element= {<LifeInnoveraMain/>} />
      <Route path="/facilities" element= {<InfraMain/>} />
      <Route path="/careers" element= {<CareerMain/>} />
      <Route path="/impact" element= {<ImpactMain/>} />
      <Route path="/team" element= {<TeamMain/>} />
      <Route path="/enroll" element= {<EnrollMain/>} />
      <Route path="/academics" element= {<AcademicsMain/>} />
      <Route path="/alumni" element= {<AcademicsMain/>} />
      <Route path="/impressions" element= {<ImpressMain/>} />
      <Route path="/gallery" element= {<ImpressMain/>} />

      <Route path="/deliver-inspiration" element= {<InspireMain/>} />
      <Route path="/expo-preparation" element= {<ExpoPrep/>} />
      <Route path="/expo-2021" element= {<Expo21/>} />
      <Route path="/garba-night" element= {<GarbaNight/>} />
      <Route path="/rakhi-making" element= {<RakhiMaking/>} />
      <Route path="/sarsvati-poojan-bhondla" element= {<Bhondla/>} />
      <Route path="/puberty-adolescence" element= {<Puberty/>} />
      <Route path="/night-camp" element= {<NightCamp/>} />
      <Route path="/field-trip" element= {<FieldTrip/>} />
      <Route path="/annual-sport" element= {<AnnualSport/>} />
      <Route path="/foster" element= {<Foster/>} />
      <Route path="/library" element= {<Library/>} />
      <Route path="/friendship-day" element= {<Friendship/>} />
      <Route path="/parents-get-together" element= {<Gettogether/>} />
      <Route path="/childrens-day" element= {<ChildrenDay/>} />
      <Route path="/sst-labs" element= {<SST/>} />
      <Route path="/resource-room" element= {<SST/>} />
      <Route path="/expo-2023" element= {<Expo23/>} />
      <Route path="/mumbai-picnic" element= {<MumbaiPicnic/>} />
      <Route path="/constellation" element= {<Constellation/>} />
      <Route path="/privacy-policy" element= {<PrivacyPolicy/>} />
      <Route path="/franchise" element= {<Franchise/>} />
      <Route path="/visit_form" element= {<VisitMainform/>} />
      <Route path="/terms_and_conditions" element= {<Terms/>} />
      <Route path="/school-newsletters" element= {<NewsLetterMain/>} />
      <Route path="/current-openings" element= {<CurrentOpeningsMain/>} />
      <Route path="/new-home" element= {<NewHome/>} />
      <Route path="/constellation-24-25" element= {<Constellation24/>} />
      <Route path="/expo-2024" element= {<Expo24/>} />
      <Route path="/independence-day-2024" element= {<Independance24/>} />
      <Route path="/mumbai-trip-2024" element= {<Mumbai24/>} />
      <Route path="/grand-parents-day" element= {<GrandP24/>} />
      <Route path="/sports-day-pre-school" element= {<Sports24/>} />
      <Route path="/sports-day-pre-school" element= {<Sports24/>} />
 
      <Route path="/refer_form" element= {<ReferMain/>} />
      <Route path="/our-school" element= {<OurSchool/>} />
      <Route path="/safe-environment" element= {<Safeenvironment/>} />

      <Route path="*" element={<ErrorPage />} />

    </Routes>

   </BrowserRouter>
  );
}

export default App;
