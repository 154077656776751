import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';
import ContactMainForm from './contact_main_form';
import { Link } from "react-scroll";
import Sidebar from '../layout/sidebar';

class Career extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Careers' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="py-5 text-center growSection">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
                <Row>
                  
                <Col lg="12">
                <h2  className="blueBg mb-4  "  rounded>Join the Family </h2>

                <h4>Innovera school is alway looking forward to expand its team with compatible atittude, talent and skills-set .</h4>

                
                </Col>

                </Row>

                </Container>

                <Container fluid >
                <Row>        
              <Col lg="4" >

              <div className="growthDiv">
                <div
                  className="growthImg1"
                  style={{
                    backgroundImage: `url(assets/images/innovera/impact/1.jpg)`,
                  }}
                >
                  <div className="growthCaption p-3 text-center">
                     <h4 className="text-center   mainText "  ><span>Love for teaching , Passion to experiment , enjoy with kids </span></h4> 
                     <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={100}
            className="btn btn-success text-center" activeclassname="active"
          >
                   As Educator</Link>
                  </div>
                </div>
              </div>
                {/* <div className='BranchBox'>
              <h3 className="text-center blink mainText "  ><span>Do you have a land you want to lease us ? </span></h3>

                <h4  className="text-center  ">  Connect with us    </h4>
                    
                </div> */}
              </Col>
              
              <Col lg="4">
              <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/impact/As-Admin.jpg)`,
                  }}>
                   <div className="growthCaption p-3 text-center">
                  <h4 className="text-center  mainText "  ><span>Passion to serve and grow  </span></h4>
                  <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-10}
            duration={100}
            className="btn btn-success text-center" activeclassname="active"
          >As Admin </Link>
                  </div>
                </div>
              </div>
              

              </Col>

              <Col lg="4">
              <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/impact/AS-Leader.jpg)`,
                  }}>
                   <div className="growthCaption p-3 text-center">
                  <h4 className="text-center  mainText "  ><span>Ability to take decision and take ownership  </span></h4>
                   <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-10}
            duration={100}
            className="btn btn-success text-center" activeclassname="active"
          >As leader</Link>
                  </div>
                </div>
              </div>
              

              </Col>
                        </Row>
            </Container>

          <div className="highlights py-5" id="connectivity">
            <Container >

            <h1 className=" pb-3 mt-0 mb-0">
           
            <span className="greenBg">Who is</span>
            <span className="greenBor"> The Right Fit</span>
          </h1>
          
          <h4 className="text-center pb-3">Someone who loves to have fun at work and enjoys upgrading knowledge consistently.</h4>

          <Row><Col lg={{span:"6" , offset:3 }}>
            
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-10}
            duration={100}
           >  <div className="BranchBox3  text-center">APPLY NOW  </div> </Link>  </Col>
          </Row>
          <Row id='contact'>
            <Col  >
                  <CareerForm/>
            </Col>
          </Row>
 
                
            </Container>
            </div>
 
         
         </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">
        <Sidebar/>



 
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default Career;